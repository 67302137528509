import React from 'react';
import Team from '../components/Team';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const TeamPage = () => {
    return (
        <div>
            <Navbar/>
            <Team/>
            <Footer/>
        </div>
    );
};

export default TeamPage;